<script>
  //import Thing from './Thing.svelte';
  import axios from 'axios'
  import { onMount } from 'svelte'
  import Form from './Form.svelte'
  import moment from "moment-timezone"

  moment.locale('en');


  //const url =
  //'http://localhost:3000/api/business/get/6fd735c9-1763-4c1b-b3c5-70771efb5e4'
  //'http://localhost:8000/api/business/get/f2ea3d1e-cf46-4724-80d8-539a562ccabd'

  let up = false
  let showform = false
  let formitems = { items: [] }
  let data = {
    active: 0,
    whatsapps: [],
  }
  let personid = null

  const now = moment()
  const today = now.format("YYYY-MM-DD")


  //console.log(now.day())

  /*console.log(moment('2010-10-22 01:10:00').tz('America/Los_Angeles').format())

  console.log(moment('2010-10-22 01:10:00').tz('America/New_York').format())

  console.log(moment('2010-10-22 '+ "01:10:00").format())

  console.log(moment().format())

  if(moment('2010-10-22').tz('America/Los_Angeles').isBefore('2010-10-21')){
    console.log("A")
  }
  else {
    console.log("B")
  }*/

  onMount(() => {
    let key = ''
    if (document.currentScript.hasAttribute('data-key')) {
      key = document.currentScript.getAttribute('data-key')
    } else {
      key = document.currentScript.getAttribute('key')
    }

    const url = "https://lf.tochat.be/api/business/get/"+key

    axios
            .get(url)
            .then(function (response) {
              data = response.data.data
              //console.log(data)
              if (data.random === true) {
                data.whatsapps = shuffle(data.whatsapps)
              }
              if (window.innerWidth < 767) {
                up = false
              } else {
                up = data.isopen
              }
            })
            .catch(function (error) {
              console.log(error)
            })
  })

  function shuffle(array) {
    let counter = array.length
    while (counter > 0) {
      let index = Math.floor(Math.random() * counter)
      counter--
      let temp = array[counter]
      array[counter] = array[index]
      array[index] = temp
    }
    return array
  }

  function close() {

    showform = false
  }

  function callmex(event) {
    let str = JSON.stringify(event.detail.data)
    str = window.btoa(str);
    callme(personid, {}, str)
  }

  function callme(id, fitems, inputdata = {}) {
    personid = id

    let empty = true

    //console.log(fitems)

    if(fitems != null && typeof fitems === 'object'){
      empty = Object.keys(fitems).length === 0 && fitems.constructor === Object
    }

    if (empty) {
      showform = false
      window.location.href =
              'https://lf.tochat.be/api/business/send/' + id +"?data="+inputdata
    } else {
      showform = true
      formitems = fitems //9da8f2c8-3868-4a57-958c-96bccd4b2384
    }
  }

  function handleClick() {
    up = !up
  }

  function datecompare(optimes, v = ' GMT+01') {
    const days = {
      1: 'MO',
      2: 'TU',
      3: 'WE',
      4: 'TH',
      5: 'FR',
      6: 'SA',
      0: 'SU',
    }

    const day = now.day()

    for (let i = 0; i < optimes.length; i++) {
      if (optimes[i].day == days[day]) {

        let start = moment(today + " "+optimes[i].availableFrom)
        let fin = moment(today + " "+optimes[i].availableUntil)

        if(optimes[i].timezone){
          start = start.tz(optimes[i].timezone)
          fin = fin.tz(optimes[i].timezone)
        }

        //console.log("start" + start.format())
        //console.log("fin" + start.format())
        //console.log("now" + now.format())

        if (now.isAfter(start) && now.isBefore(fin)) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>

<style>
  .ok_animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes ok_bounceInUp {
    0%,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 3000px, 0);
      transform: translate3d(0, 3000px, 0);
    }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, -5px, 0);
      transform: translate3d(0, -5px, 0);
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @keyframes ok_bounceInUp {
    0%,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 3000px, 0);
      transform: translate3d(0, 3000px, 0);
    }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, -5px, 0);
      transform: translate3d(0, -5px, 0);
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  .ok_bounceInUp {
    -webkit-animation-name: ok_bounceInUp;
    animation-name: ok_bounceInUp;
  }

  @-webkit-keyframes ok_bounceOutDown {
    20% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0);
    }
  }

  @keyframes ok_bounceOutDown {
    20% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0);
    }
  }

  .ok_bounceOutDown {
    -webkit-animation-name: ok_bounceOutDown;
    animation-name: ok_bounceOutDown;
  }

  @-webkit-keyframes ok_fadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes ok_fadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #okewa {
    font-family: Arial, sans-serif;
  }

  #okewa-floating_cta *,
  #okewa-floating_popup * {
    box-sizing: border-box;
  }

  #okewa-floating_cta {
    background: #0dc152;
    position: fixed;
    z-index: 999;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    user-select: none;
    line-height: 1;
    overflow: hidden;
    min-width: 40px;
    max-width: 350px;
    width:fit-content;
  }


  #okewa-floating_cta:before {
    transition: opacity 0.5s ease;
    content: '';
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 60px;
    z-index: 1;
    opacity: 0;
  }

  #okewa-floating_cta:hover:before {
    opacity: 1;
  }

  #okewa-floating_cta:hover .okewa-fc_icon {
    background-color: rgba(0, 0, 0, 0);
  }

  #okewa-floating_cta .okewa-fc_text {
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 14px;
    padding: 15px 20px 15px 15px;
    font-family: Arial, Sans-serif;
    vertical-align: sub;
    max-width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left;
  }

  #okewa-floating_cta .okewa-fc_icon {
    position: relative;
    z-index: 2;
    height: 43px;
    padding: 10px 12px;
    border-radius: 0 15px 0 0;
    background: rgba(0, 0, 0, 0.05);
    float: right;
    transition: background-color 0.5s ease;
  }

  .okewa-style_2 #okewa-floating_cta {
    bottom: 15px;
    left: 15px;
    border-radius: 100px;
  }

  .okewa-style_2 #okewa-floating_cta .okewa-fc_text {
    padding: 15px 5px 15px 15px;
  }

  .okewa-style_2 #okewa-floating_cta .okewa-fc_text.empty {
    padding: 0;
  }

  .okewa-style_2 #okewa-floating_cta .okewa-fc_icon {
    padding: 10px 14px;
  }

  @-webkit-keyframes ok_widgetPulse {
    0% {
      opacity: 0;
    }
    50% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2, 2);
      transform: scale(2, 2);
      opacity: 0;
    }
  }

  @keyframes ok_widgetPulse {
    0% {
      opacity: 0;
    }
    50% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2, 2);
      transform: scale(2, 2);
      opacity: 0;
    }
  }

  #okewa-floating_popup {
    position: fixed;
    z-index: 999;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    left: 15px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    width: 350px;
    font-family: Arial, Sans-serif;
    font-size: 14px;
    line-height: 1.4;
  }

  .okewa-style_2 #okewa-floating_popup {
    bottom: 70px;
  }

  #okewa-floating_popup .okewa-header {
    background: #0dc152;
    text-align: center;
    color: #fff;
    padding: 15px;
  }

  #okewa-floating_popup .okewa-header .okewa-close {
    position: absolute;
    left: 15px;
    top: 25px;
    border-radius: 8px;
    width: 35px;
    height: 35px;
    padding: 10px;
    cursor: pointer;
  }

  #okewa-floating_popup .okewa-header .okewa-close:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  #okewa-floating_popup .okewa-header .okewa-avatar {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    display: inline-block;
    overflow: hidden;
    background: #fff;
  }

  #okewa-floating_popup .okewa-header .okewa-avatar img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  #okewa-floating_popup .okewa-header .okewa-intro {
    margin-bottom: 0;
    margin-top: 10px;
    padding: 0;
    line-height: 1.4;
    font-size: 14px;
    color: #fff;
  }

  #okewa-floating_popup .okewa-chat {
    padding: 15px;
    background: url(../img/bg.png);
    background-size: 100%;
  }

  #okewa-floating_popup .okewa-multiple_cs .okewa-chat {
    padding: 0;
    background: #fff;
    max-height: 240px;
    overflow-y: auto;
  }

  #okewa-floating_popup .okewa-multiple_cs .okewa-chat div[class^='list-cs_'] {
    font-size: 13px;
    padding: 10px;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']:hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  #okewa-floating_popup .okewa-multiple_cs .okewa-header .okewa-avatar {
    position: relative;
    margin-left: -50px;
    left: 20px;
    border-width: 0;
    border-style: solid;
    border-radius: 60px;
    vertical-align: middle;
    overflow: hidden;
    background: #fff;
  }

  #okewa-floating_popup .okewa-multiple_cs .okewa-header .okewa-avatar img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 60px;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']
  .okewa-avatar {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    margin-right: 10px;
    overflow: hidden;
    background: #fff;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']
  .okewa-avatar
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 60px;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']
  .okewa-cs_profile {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: #666;
    font-size: 13px;
    margin-top: 5px;
    text-align: left;
    flex: 1;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']
  .okewa-cs_profile
  p {
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']
  .okewa-cs_profile
  h3 {
    margin: 5px 0 3px;
    padding: 0;
    color: #000;
    font-family: Arial, Sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']
  .okewa-cs_profile
  .okewa-cs_status {
    position: relative;
    font-size: 10px;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_']
  .okewa-cs_profile
  .okewa-cs_status:before {
    content: '';
    background: #0dc152;
    width: 7px;
    height: 7px;
    border-radius: 7px;
    position: absolute;
    top: 2px;
    right: -10px;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_'].offline
  .okewa-cs_profile
  .okewa-cs_status:before {
    background: #aaa;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat
  div[class^='list-cs_'].offline
  img.okewa-avatar {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .okewa-right #okewa-floating_cta,
  .okewa-right #okewa-floating_popup {
    left: inherit;
    right: 15px;
  }

  .okewa-left #okewa-floating_cta,
  .okewa-left #okewa-floating_popup {
    left: 15px;
    right: inherit;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat::-webkit-scrollbar-track {
    background-color: #eee;
  }

  #okewa-floating_popup .okewa-multiple_cs .okewa-chat::-webkit-scrollbar {
    width: 10px;
    background-color: #eee;
  }

  #okewa-floating_popup
  .okewa-multiple_cs
  .okewa-chat::-webkit-scrollbar-thumb {
    background-color: #ddd;
  }

  #okewa-floating_popup,
  div[id^='cs_'] {
    display: none;
  }

  @media (max-width: 767px) {
    .okewa-style_2 #okewa-floating_cta {
      bottom: 10px;
      right: 12px;
      /*width: calc(100% - 25px);
            max-width: 100%*/
    }

    .okewa-style_2 #okewa-floating_cta .okewa-fc_icon {
      border-radius: 0;
      padding: 10px 10px 10px 12px;
    }

    .okewa-style_2 #okewa-floating_popup {
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }

    #okewa-floating_popup .okewa-chat {
      background: 0 0;
    }

    #okewa-floating_popup .okewa-input {
      bottom: 0;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
    }

    #okewa-floating_popup .okewa-multiple_cs .okewa-chat {
      max-height: 100%;
      height: calc(100vh - 165px) !important;
    }
  }

  #okewa-container .okewa-social-popup a {
    padding: 0 !important;
    text-align: left !important;
  }

  @media (max-width: 767px) {
    .branded #okewa-floating_popup > a {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .okewa-style_2 #okewa-floating_popup {
      top: 40%;
    }

    .compact.okewa-style_2 #okewa-floating_popup {
      top: inherit;
      left: 5%;
      right: 5%;
      bottom: 50px;
      width: 90%;
      height: auto;
      border-radius: 8px;
    }

    .compact.okewa-style_2 #okewa-floating_popup {
      bottom: 60px;
    }

    .compact #okewa-floating_popup .okewa-multiple_cs .okewa-chat {
      height: auto !important;
      max-height: 45vh !important;
    }
  }

  .display_none {
    display: none !important;
  }

  .display_block {
    display: block !important;
  }
</style>


<div
        hidden={data.active != 1}
        id="okewa"
        class="okewa-style_2 okewa-text_3 branded {data.rightpos == 0 ? 'okewa-left' : 'okewa-right'}
  ">
  <div class="okewa-pulse_3" style="border-color:{data.color}" />
  <div
          on:click={handleClick}
          id="okewa-floating_cta"
          class="ok_animated ok_bounceInUp"
          style="background:{data.color}; z-index: 99999;">
    <span style="color:{data.textColor}"  class="okewa-fc_text {data.buttonMessage === '' ? 'empty' : ''}">
      {data.buttonMessage}
    </span>
    <img
            alt=""
            class="okewa-fc_icon"
            src="https://widget.tochat.be/icon-1.png" />
  </div>
  <div
          id="okewa-floating_popup"
          class:ok_bounceInUp={up === true}
          class:ok_bounceOutDown={up === false}
          class:display_block={up === true}
          class:display_none={up === false}
          class="ok_animated"
          style="z-index: 99999;">
    <div class="okewa-multiple_cs">

      {#if data.noHeader !== true}
      <div class="okewa-header" style="background:{data.color};">
        <img
                on:click={handleClick}
                class="okewa-close"
                alt=""
                src="https://widget.tochat.be/close.png" />
        <div class="okewa-avatar" style="border-color: {data.color}">
          <img alt="" src={data.iconUrl} />
        </div>
        <p style="color:{data.textColor}" class="okewa-intro">
          {data.widgetMessage}

        </p>
      </div>
      {/if}

      {#if data.bannerUrl !== null && data.activateBanner  === true}
        {#if data.bannerLink !== null }
          <a class="banner-link" href="{data.bannerLink}" target="_blank">
            <img style="width: 100%;height:auto" class="banner" alt="Banner" src={data.bannerUrl}/>
          </a>
        {:else}
          <img class="banner" alt="Banner" title="No available link" src={data.bannerUrl}/>
        {/if}
      {/if}


      {#if showform}
        <Form on:callmex={callmex} on:close={close} {data} {formitems} hidden={showform == false} />
      {:else}
        <div hidden={showform == true} class="okewa-chat">
          {#each data.whatsapps as person (person.number)}
            {#if person.active == 1}
              {#if person.optimes.length == 0 || datecompare(person.optimes)}
                <div
                        target="_blank"
                        style="text-decoration: none"
                        on:click={callme(person.id, person.form)}>
                  <div class="list-cs_1" href="#cs_1" data-pixel="">
                    <div class="okewa-avatar">
                      <img alt="" src={person.iconUrl} />
                    </div>
                    <div class="okewa-cs_profile">
                      <p class="okewa-cs_position">{person.post}</p>
                      <h3 class="okewa-cs_name">{person.name}</h3>
                      <small style="color: #12b03f" class="okewa-cs_status">
                        Online
                      </small>
                    </div>
                  </div>
                </div>
              {:else if (data.onlyactive != true)}
                <div class="list-cs_1" href="#cs_1" data-pixel="">
                  <div class="okewa-avatar">
                    <img alt="" src={person.iconUrl} />
                  </div>
                  <div class="okewa-cs_profile">
                    <p class="okewa-cs_position">{person.post}</p>
                    <h3 class="okewa-cs_name">{person.name}</h3>
                    <small style="color: #ccc" class="okewa-cs_offline">
                      offline
                    </small>
                  </div>
                </div>
              {/if}
            {/if}
          {/each}
        </div>
      {/if}
    </div>
  </div>
</div>
