<script>
  function toArray(data) {
    return data.split('|')
  }

  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()
  let submitted = false


  /*let vals = [
    {data:""},
    {data:""},
    {data:""}
  ];*/

  export let formitems

  export let data

  export let id

  formitems.items.forEach((a) => a.val = "")

  const close = () => {
    dispatch('close')
  }

  const sendtocallme = () => {
    submitted = true
    let haserrors = false

    for (let i = 0; i < formitems.items.length; i++) {
      if(formitems.items[i].val == "" && formitems.items[i].required === true){
        haserrors =  true
        break
      }
    }

    if(haserrors === false){
      //console.log("send!")
      dispatch('callmex',{ data: formitems.items })
    }
  }
  const error =(required, data, submision) => {
    //console.log(required, data, submision)
    if (required && data == "" && submision === true){
      return true
    }
    else{
      return false
    }
  }
</script>

<style>

  .error{
    padding:3px;
    background: darkred;
    color: white;
    font-weight: bold;
    margin-top:3px;
    border-radius: 5px;
  }

  label,
  select,
  input {
    display: block;
  }

  select,
  input,
  button {
    border: 1px solid green;
    padding: 5px 5px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
  }

  .form-element{
    margin-bottom: 10px;

  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .select {
    position: relative;
  }

  .select::after {
    content: '⌄';
    position: absolute;
    top: -10px;
    right: 5px;
    width: 20px;
    height: 20px;
    font-size: 30px;
    color: #12b03f;
  }

  button {
    border: 1px solid green;
    background: green;
    color: white;
    padding: 10px;
  }

  .box {
    padding: 10px;
    box-sizing: border-box;
  }
  .textito{
    font-weight: bold;
    padding-bottom:5px
  }
  .close{
    float:right;
    cursor: pointer;
  }

</style>

<div class="box">
  <div class="close" on:click={close}>X</div>
  {#if formitems.info != ''}
  <div class="textito">{formitems.info}</div>
      {/if}
  {#each formitems.items as item , i}
    {#if item.type == 'text' }
      <div class="form-element">
        <label>{item.label}</label>
        <input bind:value={item.val} type="text" />
          {#if error(item.required,item.val, submitted)}
            <div class="error">This field is required</div>
          {/if }
      </div>
    {:else if item.type == 'date'}
      <div class="form-element">
        <label>{item.label}</label>
        <input bind:value={item.val} type="date" />

      {#if error(item.required,item.val, submitted)}
        <div class="error">This field is required</div>
      {/if }
      </div>
    {:else if item.type == 'time'}
      <div class="form-element">
        <label>{item.label}</label>
        <input bind:value={item.val} type="time" />

      {#if error(item.required,item.val, submitted)}
        <div class="error">This field is required</div>
      {/if }</div>
    {:else if item.type == 'select'}
      <div class="form-element">
        <label>{item.label}</label>
        <div class="select">
          <select bind:value={item.val}>
            <option value="">--select--</option>
            {#each toArray(item.data) as data}
              <option value={data}>{data}</option>
            {/each}
            }
          </select>
        </div>
        {#if error(item.required,item.val, submitted)}
          <div class="error">This field is required</div>
        {/if }
      </div>
    {/if}
  {/each}
  <button style="color:{data.textColor};background: {data.color}" on:click={sendtocallme} type="button">{formitems.buttontext}</button>
</div>
